<template>
  <div>
    <input type="checkbox" :class="$style.checkboxInput" :checked="isChecked" />
    <BaseButton variant="icon" :class="$style.iconContainer" @click="onChange">
      <BaseIcon
        icon="plus-circle"
        :class="[$style.icon, isChecked ? $style.active : $style.inactive]"
      />
    </BaseButton>
  </div>
</template>
    <script>
export default {
  name: "IconCheckbox",
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.checked,
    };
  },
  watch: {
    checked(value) {
      if (value !== this.isChecked) {
        this.isChecked = value;
      }
    },
  },
  methods: {
    onChange(e) {
      this.isChecked = !this.isChecked;
      const value = this.isChecked;
      console.log("in on change", e, value);
      this.$emit("change", value, e);
      this.$emit("update:value", value, e);
    },
  },
};
</script>
<style lang="scss" module>
.checkboxInput {
  display: none;
}

.active {
  color: $color-accent;
}

.inactive {
  color: $color-grey-light;
}
</style>
